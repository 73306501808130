import { useEffect, useState } from 'react';
import localStorage, { TJsonValue } from '@atlas/tool/box/browser/localStorage';
import useThrottledCallback from '@atlas/tool/box/hooks/useThrottledCallback';

/**
 * React.useState hook that is connected to the local storage value (stores data there and sync it back)
 *
 * @template T - The type of the value to be stored in local storage.
 * @template TD - The type of the default value.
 * @param {string} key - The key used to store the value in local storage.
 * @param {TD} [defaultValue] - The default value to be used if no value is found in local storage.
 * @returns {[T | TD, TUseLocalStorageSet<T>]} - A tuple containing the current value and a setter function.
 */
export default function useLocalStorage<T extends TJsonValue, TD = T>(
    key: string,
    defaultValue?: TD,
): [T | TD, TUseLocalStorageSet<T>] {
    const [value, setValue] = useState<T | TD>(() =>
        localStorage.has(key) ? localStorage.read<T>(key) : (defaultValue as TD),
    );

    const set = useThrottledCallback<TUseLocalStorageSet<T>>(
        (setter) =>
            setValue((previous) => {
                if (typeof setter === 'function') setter = setter(previous as T);
                localStorage.write(key, setter);
                return setter;
            }),
        10,
    );

    useEffect(() => localStorage.subscribe<T>(key, (newValue) => setValue(newValue as T)), [key]);

    return [value, set];
}

// #region Types

export type { TJsonValue };

export type TUseLocalStorageSet<T> = (setter: T | ((previous: T | undefined) => T)) => void;

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, it, expect, beforeEach } = import.meta.vitest;
    describe('useLocalStorage', async () => {
        const { renderHook } = await import('@testing-library/react-hooks');

        beforeEach(() => {
            localStorage.clear();
        });

        it('updates the value immediately', () => {
            const { result } = renderHook(() => useLocalStorage('test', 0));
            expect(result.current[0]).toBe(0);
            result.current[1](1);
            expect(result.current[0]).toBe(1);
        });

        it('updates the value in localStorage', () => {
            const { result } = renderHook(() => useLocalStorage('test', 0));
            expect(localStorage.read('test')).toBe(null);
            result.current[1](1);
            expect(localStorage.read('test')).toBe(1);
        });

        it('returns localStorage value if it is defined', () => {
            localStorage.write('test', 1);
            const { result } = renderHook(() => useLocalStorage('test', 0));
            expect(result.current[0]).toBe(1);
        });

        it('rerenders with new value if the record with the given key has been changed in localStorage', async () => {
            const { result } = renderHook(() => useLocalStorage('test', 0));
            expect(result.current[0]).toBe(0);
            localStorage.write('test', 1);
            // await waitForNextUpdate();
            expect(result.current[0]).toBe(1);
        });
    });
}

// #endregion
