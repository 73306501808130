import React from "react";
import cn from "classnames";
import { Section } from "../lib/section-model";
import styles from "../styles/categories-list.module.css";
import CategoryTile from "./category-tile";
import { HelpCenterLayout } from "../lib/load-company-settings";

export type CategoriesListProps = {
  className?: string;
  categories: Section[];
  baseUrl: string;
  layout: HelpCenterLayout;
};

export default function CategoriesList({
  className,
  categories,
  baseUrl,
  layout,
}: CategoriesListProps) {
  return (
    <div className={className}>
      <ul className={styles.categoriesList}>
        {categories.map((category) => (
          <li
            key={category.slug}
            className={cn(styles.categoriesListItem, {
              [styles.categoriesListItemGrid]:
                layout === "standard_grid" || layout === "standard_condensed",
              [styles.categoriesListItemGridCondensed]:
                layout === "standard_condensed",
            })}
          >
            <CategoryTile
              category={category}
              baseUrl={baseUrl}
              inline={layout !== "standard_grid"}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
