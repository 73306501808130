import Link from "next/link";
import React, { HTMLAttributes } from "react";
import cn from "classnames";
import { isEmpty as _isEmpty } from "lodash-es";
import { Article } from "../lib/article-model";
import styles from "../styles/article-tile.module.css";

export type { Article };

interface ArticleTileContentProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  article: Article;
}

function ArticleTileContent({
  className,
  article,
  ...divProps
}: ArticleTileContentProps) {
  return (
    <div className={className} {...divProps}>
      <header
        className={cn(styles.articleTileHeader, {
          [styles.articleTileHeaderOnly]: !article.description,
        })}
      >
        <h2
          className={styles.articleTileTitle}
          title={article.title}
          itemProp="name"
        >
          {article.title}
        </h2>
      </header>
      {article.description && (
        <p
          className={styles.articleTileDescription}
          title={article.description}
          itemProp="description"
        >
          {article.description}
        </p>
      )}
    </div>
  );
}

export type ArticleTileProps = {
  className?: string;
  article: Article;
  baseUrl: string;
  noLink?: boolean;
  query?: string;
  condensed?: boolean;
};

export default function ArticleTile({
  className,
  article,
  baseUrl,
  noLink,
  query,
  condensed,
}: ArticleTileProps) {
  if (noLink) {
    return (
      <ArticleTileContent
        className={cn(
          className,
          styles.articleTile,
          condensed && styles.articleTileCondensed
        )}
        article={article}
        itemScope
        itemType="http://schema.org/Article"
      />
    );
  }
  const _url = _isEmpty(query)
    ? `${baseUrl}/articles/${article.slug}` // making it uniform with search field results
    : `${baseUrl}/articles/${article.slug}?SEARCHED_EVENT=${query}`;
  return (
    <div itemScope itemType="http://schema.org/Article">
      <Link
        href={_url}
        className={cn(
          className,
          styles.articleTile,
          styles.articleTileLink,
          condensed && styles.articleTileCondensed
        )}
        itemProp="url"
      >
        <svg
          className={styles.articleTileLinkChevron}
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.13368 6.24589C7.78124 6.61897 7.78092 7.20217 8.13295 7.57563L11.8325 11.5004L8.13296 15.4252C7.78092 15.7987 7.78124 16.3819 8.13368 16.7549C8.51596 17.1596 9.15984 17.1596 9.54212 16.7549L13.8572 12.1871C14.2213 11.8017 14.2213 11.1991 13.8572 10.8137L9.54212 6.24588C9.15984 5.84122 8.51596 5.84122 8.13368 6.24589Z"
            fill="#747474"
          />
        </svg>
        <ArticleTileContent article={article} />
      </Link>
    </div>
  );
}
