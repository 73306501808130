import { useRef } from 'react';
import useRefCallback from 'use-ref-callback';

/**
 * Returns a throttled version of the provided callback function.
 * The throttled callback will only be invoked once within the specified delay.
 *
 * @template T - The type of the callback function.
 * @param {T} callback - The callback function to be throttled.
 * @param {number} [delay=1000] - The delay in milliseconds before invoking the callback again.
 * @returns {T} - The throttled callback function.
 */
export default function useThrottledCallback<T extends (...args: any[]) => any>(callback: T, delay = 1e3): T {
    const cb = useRefCallback(callback);
    const cachedRef = useRef<undefined | { time: number; value: ReturnType<T> }>(undefined);

    const memoized = useRefCallback((...args: Parameters<T>) => {
        const cached = cachedRef.current;
        if (cached && cached.time > Date.now() - delay) {
            return cached.value;
        }

        cachedRef.current = {
            time: Date.now(),
            value: cb(...args),
        };

        return cachedRef.current.value;
    });

    return memoized as T;
}

// #region Tests

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    describe('useThrottledCallback', async () => {
        const { renderHook } = await import('@testing-library/react-hooks');

        it('calculates the value immediately', async () => {
            const { result } = renderHook(() => useThrottledCallback(() => 1));
            expect(result.current()).toBe(1);
        });

        it('does not execute callback when it is called second time if time has not passed', async () => {
            let i = 0;
            const { result } = renderHook(() => useThrottledCallback(() => ++i));
            result.current();
            result.current();
            expect(i).toBe(1);
        });

        it('executes callback when it is called second time if time has passed', async () => {
            let i = 0;
            const { result } = renderHook(() => useThrottledCallback(() => ++i));
            result.current();
            await new Promise((r) => setTimeout(r, 1000));
            result.current();
            expect(i).toBe(2);
        });
    });
}

// #endregion
