import React, { useMemo } from 'react';
import useRefCallback from 'use-ref-callback';
import useLocalStorage from '@atlas/tool/box/hooks/useLocalStorage';

export default function useUiConfig() {
    const uiConfig = React.useContext(UiConfigContext);
    if (!uiConfig) return { ...defaultState, change: () => {} };
    return uiConfig;
}

// #region Helpers

const defaultState: TUiConfigState = {
    sidePaneWidth: 425,
    useOnboardingPadding: false,
};

const UiConfigContext = React.createContext<TUiConfig | null>(null);

export function UiConfigProvider(props: TUiConfigProviderProps): React.ReactElement {
    const { value, children } = props;

    const [state, setState] = useLocalStorage<TUiConfigState>('ui-config', defaultState);

    const change = useRefCallback<TUiConfigMethods['change']>((key, value) =>
        setState(
            (prev) =>
                ({
                    ...prev,
                    [key]: typeof value === 'function' ? value((prev as TUiConfigState)[key]) : value,
                } as TUiConfigState),
        ),
    );

    const uiConfig = useMemo(() => ({ ...state, ...value, change }), [state, change]);

    return <UiConfigContext.Provider value={uiConfig} children={children} />;
}

// #endregion

// #region Types

export type TUiConfigState = {
    sidePaneWidth: number;
    useOnboardingPadding: boolean;
};

export type TUiConfigMethods = {
    change<T extends keyof TUiConfigState>(
        key: T,
        value: TUiConfigState[T] | ((previous: TUiConfigState[T]) => TUiConfigState[T]),
    ): void;
};

export type TUiConfig = TUiConfigState & TUiConfigMethods;

export type TUiConfigProviderProps = {
    children: React.ReactNode;
    value?: Partial<TUiConfigState>;
};

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, it, expect } = import.meta.vitest;
    describe('useUiConfig', async () => {
        const { renderHook } = await import('@testing-library/react-hooks');
        it('does something', () => {
            const { result } = renderHook(() => useUiConfig(), { wrapper: UiConfigProvider });
            expect(result.current).toHaveProperty('sidePaneWidth');
        });
    });
}

// #endregion
