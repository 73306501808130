import Link from "next/link";
import React from "react";
import cn from "classnames";
import { Section } from "../lib/section-model";
import styles from "../styles/category-tile.module.css";

type CategoryTileContentProps = {
  category: Section;
  inline?: boolean;
};

function CategoryTileContent({ category, inline }: CategoryTileContentProps) {
  return (
    <React.Fragment>
      <figure
        className={cn(styles.categoryTileIcon, {
          [styles.categoryTileIconInline]: inline,
        })}
      >
        {category.icon || "📁"}
      </figure>
      <strong
        className={cn(styles.categoryTileTitle, {
          [styles.categoryTileTitleSingle]: !category.description,
        })}
        title={category.title}
      >
        {category.title}
      </strong>
      <span
        className={styles.categoryTileDescription}
        title={category.description}
      >
        {category.description}
      </span>
    </React.Fragment>
  );
}

export type CategoryTileProps = {
  className?: string;
  category: Section;
  baseUrl: string;
  noLink?: boolean;
  inline?: boolean;
  minmal?: boolean; // TODO: Not implemented yet, but should hide amount authors and articles
};

export default function CategoryTile({
  className,
  category,
  baseUrl,
  noLink,
  inline,
}: CategoryTileProps) {
  if (noLink) {
    return (
      <div className={`${className || ""} ${styles.categoryTile}`}>
        <CategoryTileContent category={category} inline={inline} />
      </div>
    );
  }

  const categoryUrl = `${baseUrl}/categories/${category.slug}`;

  const categoryMicroData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    url: categoryUrl,
    name: category.title,
    description: category.description ?? "",
  });

  return (
    <Link
      href={categoryUrl}
      className={`${className || ""} ${styles.categoryTile} ${
        styles.categoryTileLink
      }`}
    >
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: categoryMicroData }}
      />
      <CategoryTileContent category={category} inline={inline} />
    </Link>
  );
}
