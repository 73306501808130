import React from "react";
import ArticleTile, { Article } from "./article-tile";
import styles from "../styles/articles-list.module.css";

export type ArticlesListProps = {
  className?: string;
  articles: Article[];
  baseUrl: string;
  expand?: boolean;
  query?: string;
};

export default function ArticlesList({
  className,
  articles,
  baseUrl,
  expand,
  query,
}: ArticlesListProps) {
  return (
    <div
      className={`${className || ""}${
        expand ? ` ${styles.articlesListExpanded}` : ""
      }`}
    >
      <ul className={styles.articlesList}>
        {articles.map((article) => {
          return (
            <li key={article.slug} className={styles.articlesListItem}>
              <ArticleTile article={article} baseUrl={baseUrl} query={query} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
