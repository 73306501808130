import window from '@atlas/tool/box/browser/window';

export default window || globalThis;

// #region Tests

if (import.meta.vitest) {
    const { describe, it } = import.meta.vitest;
    describe('windowOrGlobal', () => {
        it.todo('🤷');
    });
}

// #endregion
