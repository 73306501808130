import { useState, useEffect } from 'react';
import window from '@atlas/tool/box/browser/window';

function usePrefersColorScheme(): TPrefersColorScheme {
    const [colorScheme, setColorScheme] = useState(getCurrentColorScheme);

    useEffect(() => {
        if (!window) return;

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = () => setColorScheme(mediaQuery.matches ? 'dark' : 'light');

        // Because time passed between call of the main hook and useEffect
        handleChange();

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    return colorScheme;
}

export default usePrefersColorScheme;

// #region Helpers

function getCurrentColorScheme(): TPrefersColorScheme {
    return window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}

// #endregion

// #region Types

type TPrefersColorScheme = 'dark' | 'light';

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, it } = import.meta.vitest;
    describe('usePrefersColorScheme', () => {
        it('🤷', () => {});
    });
}

// #endregion
