// TODO: Fix types
import windowOrGlobal from '@atlas/tool/box/browser/windowOrGlobal';

export default function parseJson<T = TJsonValue, TDefault = undefined>(
    json: unknown,
    defaultValue?: TDefault,
): T | TDefault {
    if (typeof json !== 'string') return defaultValue as TDefault;

    try {
        return windowOrGlobal.JSON.parse(json);
    } catch (error) {
        console.error(`Could not parse JSON string: ${json}\n`, error);
        return defaultValue as TDefault;
    }
}

// #region Types

export type TJsonValue = null | string | number | boolean | { [x: string]: TJsonValue } | TJsonValue[];

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, test, expect, beforeEach, afterEach, vi } = import.meta.vitest;
    describe('parseJson', async () => {
        beforeEach(() => {
            vi.spyOn(console, 'error').mockReturnValue(undefined);
        });

        afterEach(() => {
            vi.resetAllMocks();
        });

        test('ignores any non-string value', () => {
            expect(parseJson(0)).toBe(undefined);
            expect(parseJson(1)).toBe(undefined);
            expect(parseJson(undefined)).toBe(undefined);
            expect(parseJson(null)).toBe(undefined);
            expect(parseJson({ name: 'Igor' })).toBe(undefined);
            expect(parseJson([3, 4, 5])).toBe(undefined);
            expect(parseJson(true)).toBe(undefined);
        });

        test('returns undefined for invalid or empty strings', () => {
            expect(console.error).toBeCalledTimes(0);
            expect(parseJson('')).toBe(undefined);
            expect(console.error).toBeCalledTimes(1);
            expect(console.error).toBeCalledWith('Could not parse JSON string: \n', expect.any(SyntaxError));

            expect(parseJson('{')).toBe(undefined);
        });

        test('parses given json string', () => {
            const object = { a: 1, b: '2', c: true, d: [], e: {} };
            const json = windowOrGlobal.JSON.stringify(object);
            expect(parseJson(json)).toEqual(object);
            expect(console.error).toBeCalledTimes(0);
        });

        test('returns default value if given', () => {
            expect(parseJson('', 42)).toBe(42);
            expect(parseJson('{', 42)).toBe(42);
            expect(parseJson(true, 42)).toBe(42);
        });
    });
}

// #endregion
